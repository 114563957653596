import React from 'react'
import LoginForm from '../components/LoginForm/LoginForm'

function Login() {
    return (
        <div>
            <LoginForm />
        </div>
    )
}

export default Login
